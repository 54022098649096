import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import "whatwg-fetch";
require('dotenv').config()

const SECRET = process.env.REACT_APP_CLIENT_SECRET;
const CLIENT = "847977806654734346";
const REDIR = process.env.REACT_APP_REDIR;

export default function App() {
  const [authed, setAuthed] = useState();

  useEffect(()=> {
    let search = window.location.search;
    let authcode = search.split("=")[1]
    console.log(authcode)
    if(authcode != undefined) {
      auth(authcode);
    }
  })

  return (
         
    <div className="App">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
      </style>
      <Router>
        <Switch>
          <Route exact path="/:code" component={Panel}/>
        </Switch>
      </Router>
      <h1>
        {authed && <Panel authed={authed}/>}
      </h1>
        
      <a id="login" style={{display: "block"}} href="https://discord.com/api/oauth2/authorize?client_id=847977806654734346&redirect_uri=https%3A%2F%2Fcopperdashbackend.pages.dev%2F&response_type=code&scope=identify">
        <button>Login with discord</button>
      </a>
    </div>
    
  );
}

function Panel({authed}){
  let search = window.location.search;
  let authcode = search.split("=")[1]
  const [code, setCode] = useState('')
  
  console.log(code);
  
  const location = useLocation()
  const history = useHistory()
  
  useEffect(() => {
    const params = new URLSearchParams()
    if (code) {
      params.append("code", authcode)
    } else {
      params.delete("code")
    }
    history.push({search: params.toString()})
  }, [code, history])

  return (
    <div>
      <h1>hi</h1>
    </div>
  )
}
function auth(code) {
  //Build request
  let json = {
    'client_id': CLIENT,
    'client_secret': SECRET,
    'grant_type': 'authorization_code',
    'code': code,
    'redirect_uri': REDIR
  }
  console.log(json);
  //Create urlencoded form
  let formBody = [];
  for (let property in json) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(json[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  fetch('https://discord.com/api/v8/oauth2/token', {
    method: 'POST',
    body: formBody,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  }).then(res => res.json())
  .then(info => {
    console.log(info)
                    fetch(`https://discordapp.com/api/users/@me`, {
                        headers: {
                            authorization: `${info.token_type} ${info.access_token}`,
                        },
                    }).then(response => response.json())
                        .then(info => {
                            console.log(info);
                            document.write(`<h1 style="font-family: 'Roboto', sans-serif;">Hello ${info.username}</h1>`)
                        })
  });

}